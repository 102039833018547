import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import CardError from './CardError';
import CircularProgress from '@mui/material/CircularProgress';

const PaymentForm = (props) => {
  const { submitSelections, paymentIntentId, freeEntryCount } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    // TODO: UPDATE URL
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      submitSelections(paymentIntentId, freeEntryCount);
    }
  };

  const spinner = loading ? (
    <CircularProgress size={17} thickness={5} />
  ) : (
    <></>
  );

  return (
    <>
      <CardError error={errorMessage} />
      <PaymentElement />
      <button
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        onClick={handleSubmit}
        disabled={!stripe}
        style={{ float: 'right' }}
      >
        {spinner} Confirm Payment
      </button>
    </>
  );
};

export default PaymentForm;
