import { createContext, useState, useEffect } from 'react';
import currencyToSymbolMap from 'currency-symbol-map/map';
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [referralCode, setReferralCode] = useState(
    localStorage.getItem('referralCode') || '',
  );

  const [cartItems, setCartItems] = useState(
    localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
  );

  // We shoudn't use local storage for this.
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    localStorage.setItem('referralCode', referralCode);
  }, [cartItems]);

  // We shoudn't use local storage for this.
  useEffect(() => {
    const cartItems = localStorage.getItem('cartItems');
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
    const referralCode = localStorage.getItem('referralCode');
    if (referralCode) {
      setReferralCode(referralCode);
    }
  }, []);

  const addToCart = (item, referralCode) => {
    if (cartItems.length > 0 && cartItems[0].sweep.id !== item.sweep.id) {
      console.log('Cart has items for a different sweep. Cannot add item.');
      return;
    }
    setCartItems([...cartItems, { ...item, quantity: 1 }]);
    if (referralCode !== '' && referralCode !== null) {
      setReferralCode(referralCode);
    }
  };

  const removeFromCart = (item) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
  };

  const clearCart = () => {
    setCartItems([]);
    setReferralCode('');
  };

  const canAddToCart = (sweepId) => {
    if (cartItems.length > 0 && cartItems[0].sweep.id !== sweepId) {
      return false;
    }
    return true;
  };

  const totalPrice = () => {
    return cartItems.reduce(
      (acc, item) => acc + parseInt(item.sweep.entry_fee) / 100,
      0,
    );
  };

  const totalPriceInCurrency = () => {
    if (cartItems.length === 0) {
      return currencyToSymbolMap['EUR'] + '0';
    }
    return (
      currencyToSymbolMap[getSweep()?.currency?.toUpperCase() || 'EUR'] +
      totalPrice()
    );
  };

  const getSweep = () => {
    return cartItems.length > 0 ? cartItems[0].sweep : null;
  };

  const getEmail = () => {
    return cartItems.length > 0 ? cartItems[0].email : null;
  };

  const hasCartItems = () => {
    return cartItems.length > 0;
  };

  const getReferralCode = () => {
    return referralCode;
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        totalPrice,
        totalPriceInCurrency,
        canAddToCart,
        getSweep,
        getReferralCode,
        getEmail,
        hasCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
