import { useContext } from 'react';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

import { CartContext } from '../../../context/cart';

function ItemList() {
  const { cartItems, removeFromCart, getSweep } = useContext(CartContext);
  const navigate = useNavigate();
  return (
    <List>
      {cartItems.length > 0 && (
        <ListItem>
          <ListItemText
            primary={getSweep().organisation.name + ' Entries'}
            primaryTypographyProps={{
              style: { fontWeight: 'bold' },
            }}
          />
        </ListItem>
      )}
      {cartItems.map((item) => (
        <ListItem
          // onMouseOver={() => alert("test")}
          key={item.id}
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon onClick={() => removeFromCart(item)} />
            </IconButton>
          }
        >
          <ListItemText
            primary={item.sweep.competition.name}
            secondary={'Team Name: ' + item.nickName}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default ItemList;
