/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useContext } from 'react';
import { getOptions } from '../../../Helpers/HorseAuctionHelper';
import { toast } from 'react-toastify';

import { CartContext } from '../../../context/cart';

export const useHorseAuctionForm = ({ sweep, setNumberOfGroups }) => {
  const { addToCart } = useContext(CartContext);
  const [state, setState] = useState({
    options: [],
    picks: {},
  });

  useEffect(() => {
    if (sweep && sweep.competition.sport === 'horse_auction') {
      getOptions(sweep, updateOptions);
    }
  }, [sweep]);

  const updateOptions = (optionsData) => {
    setNumberOfGroups(5);
    setState({
      ...state,
      options: optionsData,
    });
  };

  const handleOptionPicked = (optionId, sex, stallion, dam, consignor) => {
    if (Object.keys(state.picks).length < 5) {
      setState({
        ...state,
        picks: {
          ...state.picks,
          [optionId]: {
            sex,
            optionId,
            stallion,
            dam,
            consignor,
          },
        },
      });
    } else {
      toast.error("You've already selected 5 yearlings.", {
        toastId: 'already_selected_5',
      });
    }
  };

  const handleOptionUnpicked = (optionId) => {
    const picks = state.picks;
    delete picks[optionId];
    setState({
      ...state,
      picks: picks,
    });
  };

  const addEntryToCart = (
    sweep,
    userId,
    email,
    nickName,
    referralCode,
    tieBreaker,
  ) => {
    const cartItem = {
      id: sweep.id + '-' + userId + '-' + nickName + '-' + Date.now(),
      sweep,
      userId,
      email,
      nickName,
      tieBreaker,
      picks: state.picks,
    };
    addToCart(cartItem, referralCode);
    resetPicks();
  };

  function resetPicks() {
    setState({
      ...state,
      group_1: '',
      group_2: '',
      group_3: '',
      group_4: '',
      group_5: '',
      randomPick: false,
    });
  }

  return {
    handlers: {
      addEntryToCart,
      handleOptionPicked,
      handleOptionUnpicked,
    },
    context: {
      options: state.options,
      picks: state.picks,
    },
  };
};
