/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Field from '../Form/Fields/Field';

function Tiebreak(props) {
  const {
    tieBreaker,
    label,
    errors,
    onBlur,
    handleChange,
    previousSlide,
    nextSlide,
  } = props;

  const progressButton =
    tieBreaker && tieBreaker !== 0 && tieBreaker !== '0' ? (
      <button
        onClick={() => nextSlide()}
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        Next
      </button>
    ) : (
      <button
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
        disabled
      >
        Next
      </button>
    );

  return (
    <>
      <h4 className="sweeps-font-medium w3-text-teal">Tiebreaker</h4>
      <p>
        The following question is used in the case of ties in the leaderboard.
      </p>
      <Field
        label={label}
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="tieBreaker"
        value={tieBreaker}
        type="number"
      />
      <button
        onClick={() => previousSlide()}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Back
      </button>
      {progressButton}
    </>
  );
}

export default Tiebreak;
