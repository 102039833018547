/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Field from '../Form/Fields/Field';
import { CartContext } from '../../context/cart';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
function NickNameSlide(props) {
  const {
    nickName,
    errors,
    onBlur,
    handleChange,
    previousSlide,
    nextSlide,
    lastStep,
    hidePrevious,
    sweepId,
    addToCart,
  } = props;

  const navigate = useNavigate();
  const { canAddToCart } = useContext(CartContext);

  const proceedToCheckout = () => {
    if (!lastStep) {
      addToCart(true);
      navigate('/checkout');
    } else {
      nextSlide();
    }
  };

  useEffect(() => {
    if (!canAddToCart(sweepId)) {
      toast.error(
        'You cannot add items to the cart from two different sweeps.',
      );
    }
  }, [canAddToCart(sweepId)]);

  const errorMessage = lastStep
    ? 'You must populate the nick name field'
    : nickName !== '' && /\S/.test(nickName)
    ? 'You cannot add items to the cart from two different sweeps.'
    : 'You must populate the nick name field';

  const progressButton =
    nickName !== '' &&
    /\S/.test(nickName) &&
    !errors &&
    (lastStep || canAddToCart(sweepId)) ? (
      <button
        onClick={() => proceedToCheckout()}
        data-testid="nextButton"
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        {lastStep ? 'Confirm Edit' : 'Checkout'}
      </button>
    ) : (
      <Tooltip title={errorMessage}>
        <button
          className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
          data-testid="disabledNextButton"
          disabled
        >
          {lastStep ? 'Confirm Edit' : 'Checkout'}
        </button>
      </Tooltip>
    );

  const addToCartButton =
    nickName !== '' &&
    /\S/.test(nickName) &&
    !errors &&
    canAddToCart(sweepId) ? (
      <button
        onClick={() => addToCart(false)}
        data-testid="addToCartButton"
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        Add to cart
      </button>
    ) : (
      <Tooltip title={errorMessage}>
        <button
          className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
          data-testid="disabledAddToCartButton"
          disabled
        >
          Add to cart
        </button>
      </Tooltip>
    );

  return (
    <div className="w3-padding">
      <Field
        label="Nick Name"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="nickName"
        value={nickName}
      />

      {!hidePrevious && (
        <button
          onClick={() => previousSlide()}
          className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Back
        </button>
      )}

      {!lastStep && addToCartButton}
      {progressButton}
    </div>
  );
}

export default NickNameSlide;
