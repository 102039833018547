/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { prettyDate } from '../../Helpers/MiscHelpers';
import Card from '../Layouts/Card';
import DOMPurify from 'dompurify';
import PrizeWrapper from '../Prizes/Wrapper';

function HowItWorks(props) {
  const { nextSlide, sweep, competition, pickTeam, children } = props;

  const cleanHTML = DOMPurify.sanitize(sweep?.organisation?.bio, {
    USE_PROFILES: { html: true },
  });

  return (
    <div className=" w3-margin-top" data-testid="howItWorks">
      <div className="w3-twothird">
        <Card>
          <>
            <h3 className="w3-center w3-text-teal sweeps-font-bold  ">
              {sweep.competition?.name}
              {!sweep.the_sweep && !sweep.private_sweep && (
                <> sweep in aid of {sweep?.organisation?.name} </>
              )}
            </h3>

            <div className="w3-hide-large w3-hide-medium">
              {nextSlide && (
                <div className="w3-center">
                  <button
                    data-testid="startPicking"
                    onClick={() => nextSlide()}
                    className="w3-btn w3-margin-top w3-margin-bottom w3-text-white w3-sviolet"
                  >
                    Start picking!
                  </button>
                  {pickTeam && (
                    <button
                      data-testid="randomPickTeam"
                      onClick={() => pickTeam()}
                      className="w3-margin-left w3-btn w3-margin-top w3-margin-bottom w3-text-white w3-sviolet"
                    >
                      I don't know who to pick, <br /> pick for me!
                    </button>
                  )}
                </div>
              )}
            </div>
            {children}
            {nextSlide && (
              <div className="w3-center">
                <button
                  data-testid="startPicking"
                  onClick={() => nextSlide()}
                  className="w3-btn w3-margin-top w3-margin-bottom w3-text-white w3-sviolet"
                >
                  Start picking!
                </button>
                {pickTeam && (
                  <button
                    onClick={() => pickTeam()}
                    data-testid="randomPickTeam"
                    className="w3-margin-left w3-btn w3-margin-top w3-margin-bottom w3-text-white w3-sviolet"
                  >
                    I don't know who to pick, <br /> pick for me!
                  </button>
                )}
              </div>
            )}
          </>
        </Card>
      </div>
      <div className="w3-third">
        <Card>
          <img
            src={sweep?.image_url}
            alt={competition?.name}
            style={{ width: '100%' }}
          />
        </Card>

        <Card>
          <div className="w3-padding">
            <PrizeWrapper sweep={sweep} />
          </div>
        </Card>

        {nextSlide && (
          <Card>
            <div className="w3-center">
              <button
                data-testid="startPicking"
                onClick={() => nextSlide()}
                className="w3-btn sweeps-font-bold w3-margin-top w3-margin-bottom w3-text-white w3-teal"
              >
                <span className="sweeps-font-bold">START PICKING!</span>
              </button>
              {pickTeam && (
                <button
                  onClick={() => pickTeam()}
                  data-testid="randomPickTeam"
                  className="btn-margin-left w3-btn w3-margin-top w3-margin-bottom w3-text-white w3-teal"
                >
                  <span className="sweeps-font-bold">
                    RANDOMLY PICK <br /> MY TEAM!
                  </span>
                </button>
              )}
            </div>
          </Card>
        )}

        <Card>
          <div className="w3-padding">
            <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
              Entry Details
            </h3>
            <ul
              style={{
                listStyle: 'none',
                marginBlockStart: '0',
                paddingInlineStart: '0',
              }}
            >
              <li>
                <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                Entries open on{' '}
                {prettyDate(sweep?.competition?.entry_open_date)}
              </li>
              <li>
                <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                Entries close on{' '}
                {prettyDate(sweep?.competition?.entry_close_date)}
              </li>
              <li>
                <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                Tournament starts on{' '}
                {prettyDate(sweep?.competition?.start_date)}
              </li>
              <li>
                <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                Tournament ends on {prettyDate(sweep?.competition?.end_date)}
              </li>
            </ul>
          </div>
        </Card>

        {!sweep.the_sweep && (
          <Card>
            <div className="w3-padding">
              <h5 className="w3-large sweeps-font-bold w3-text-sviolet">
                About the organiser:
              </h5>
              <div dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}

export default HowItWorks;
