import { useContext } from 'react';
import axios from 'axios';
import { CartContext } from '../../../context/cart';

// eslint-disable-next-line no-undef
const apiUrl = process.env.REACT_APP_API_URL;

export const useGolfCheckout = () => {
  const { cartItems, getReferralCode } = useContext(CartContext);

  const onSubmit = (
    paymentId,
    freeEntryCount,
    callbackSuccess,
    callbackError,
  ) => {
    const allFreeEntries = freeEntryCount > cartItems.length ? true : false;
    axios
      .post(apiUrl + '/entries/create', {
        payment_intent_id: paymentId,
        referral_code: getReferralCode(),
        all_free_entries: allFreeEntries,
        entries: cartItems.map((item, index) => ({
          free_entry: allFreeEntries || index < freeEntryCount,
          user_id: item.userId,
          email: item.email,
          nickName: item.nickName,
          group_1: item.group_1,
          group_2: item.group_2,
          group_3: item.group_3,
          group_4: item.group_4,
          group_5: item.group_5,
          sweep_id: item.sweep.id,
          tie_breaker: item.tieBreaker,
        })),
      })
      .then(function (response) {
        if (response.data.message.includes('Successfully created entries')) {
          callbackSuccess(response.data.referral_code);
        }
      })
      .catch(function (error) {
        callbackError(error);
      });
  };

  const getObjectShape = () => {
    return cartItems.map((item) => ({
      group_1: item.group_1,
      group_2: item.group_2,
      group_3: item.group_3,
      group_4: item.group_4,
      group_5: item.group_5,
      tie_breaker: item.tieBreaker,
      nickName: item.nickName,
      sweep_id: item.sweep.id,
    }));
  };

  return {
    handlers: {
      onSubmit,
      getObjectShape,
    },
  };
};
