/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Field from '../../Form/Fields/Field';
import CheckboxField from '../../Form/Fields/CheckboxField';
import Error from '../../Form/Error';
import { Grid } from '@mui/material';

function RegisterUserFields(props) {
  const {
    progressEnabled,
    onBlur,
    handleChange,
    handlePhoneChange,
    name,
    email,
    emailConfirmation,
    password,
    confirmPassword,
    phone,
    over18,
    signupTerms,
    previousSlide,
    createAccount,
    errors,
  } = props;
  const progressButton = progressEnabled ? (
    <button
      onClick={() => createAccount()}
      className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
    >
      Continue
    </button>
  ) : (
    <button
      className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      disabled
    >
      Continue
    </button>
  );

  return (
    <React.Fragment>
      {errors && errors['form'] && <Error error={errors['form']} />}
      <Grid>
        <Grid item sm={12} sx={{ padding: '5px' }}>
          <Field
            label="Name"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="name"
            value={name}
            placeholder="Joe Blogs"
          />
        </Grid>
        <Grid item sm={12} lg={6} sx={{ padding: '5px' }}>
          <Field
            label="Email"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="email"
            value={email}
            placeholder="email@address.com"
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <Field
            label="Confirm Email"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="emailConfirmation"
            value={emailConfirmation}
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <Field
            label="Password"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="password"
            value={password}
            type="password"
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <Field
            label="Confirm Password"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="confirmPassword"
            value={confirmPassword}
            type="password"
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <Field
            label="Phone"
            errors={errors}
            onBlur={onBlur}
            handlePhoneChange={handlePhoneChange}
            name="phone"
            value={phone}
            type="phone"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <CheckboxField
            label="Confirm you're over 18"
            onBlur={onBlur}
            handleChange={handleChange}
            name="over18"
            value={over18}
            type="checkbox"
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ padding: '5px' }}>
          <CheckboxField
            label=" Confirm you agree to our terms:"
            onBlur={onBlur}
            handleChange={handleChange}
            name="signupTerms"
            value={signupTerms}
            type="checkbox"
          />
          <a
            className="w3-text-teal"
            onClick={() =>
              window.open(`${window.location.origin}/terms`, '_blank')
            }
          >
            Terms and Conditions
          </a>
        </Grid>
      </Grid>

      {previousSlide && (
        <button
          onClick={() => previousSlide()}
          className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Back
        </button>
      )}

      {progressButton}
    </React.Fragment>
  );
}

export default RegisterUserFields;
