import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSweep } from '../../Helpers/SweepsHelper';

import SuccessPanel from '../Form/SuccessPanel';
import Form from './Form';

function CreateSweep(props) {
  const { user } = props;

  const navigate = useNavigate('');
  if (!user?.adminAccess) {
    navigate('/');
  }

  const [state, setState] = React.useState({
    the_sweep: false,
    organisation_id: '',
    competition_id: '',
    prize_breakdown: '',
    connected_account_id: '',
    currency: 'eur',
    alias: '',
    user_id: user?.userId,
    failedCreate: false,
    file: undefined,
    successfulCreate: false,
    entry_fee: undefined,
    platform_fee: undefined,
    private_sweep: false,
    admins: [],
    errors: {},
    anyscor: false,
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
  }, []);

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
          form: undefined,
        },
      });
    }
  }

  function handleChange(evt) {
    let value;
    if (evt.target.type === 'checkbox') {
      value = evt.target.checked;
    } else if (evt.target.type === 'file') {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function handleCreateSweepResponse(response) {
    if (response.data) {
      if (response.status === 200) {
        setState({
          ...state,
          failedCreate: false,
          successfulCreate: true,
        });
      }
    }
    if (response.response) {
      setState({
        ...state,
        successfulCreate: false,
        failedCreate: true,
      });
    }
  }

  return (
    <>
      {state.successfulCreate && (
        <SuccessPanel message="Successfully created competition" />
      )}
      {state.failedCreate && 'Failed to create competition'}
      <h3 className="sweeps-font-bold w3-text-teal">Create Sweep</h3>
      <Form
        user={user}
        errors={state.errors}
        onBlur={onBlur}
        handleChange={handleChange}
        organisation_id={state.organisation_id}
        the_sweep={state.the_sweep}
        competition_id={state.competition_id}
        prize_breakdown={state.prize_breakdown}
        entry_fee={state.entry_fee}
        platform_fee={state.platform_fee}
        private_sweep={state.private_sweep}
        file={state.file}
        alias={state.alias}
        anyscor={state.anyscor}
        currency={state.currency}
      />

      <button
        onClick={() => createSweep(user, state, handleCreateSweepResponse)}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Create
      </button>
    </>
  );
}

export default CreateSweep;
