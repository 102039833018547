import React from 'react';
import currencyToSymbolMap from 'currency-symbol-map/map';

import { getPrizeForKey } from '../../../Helpers/PrizeHelper';

function Supporting(props) {
  const { entryCount, sweep } = props;
  const raisedAmount = getRaisedAmount(entryCount, sweep)?.toFixed(2);
  return (
    <div className="w3-display-container no-margin-top">
      <img
        src={sweep?.image_url}
        className="w3-center"
        style={{ width: '100%', display: 'block', margin: 'auto' }}
        alt={sweep?.organisation?.name}
      />
      {!sweep.the_sweep && !sweep.private_sweep && (
        <>
          <p className="w3-center w3-margin-top">
            This sweep is in aid of{' '}
            <a
              href={sweep?.organisation?.link}
              rel="noreferrer"
              className="w3-text-teal"
              target="_blank"
            >
              {sweep?.organisation?.name}
            </a>
          </p>
          {raisedAmount > 250 && (
            <>
              <p className="w3-center w3-large">
                <i
                  className={`fa fa-gift fa-fw w3-margin-right w3-large w3-text-teal`}
                />
                You've helped raise{' '}
                {currencyToSymbolMap[sweep?.currency?.toUpperCase()]}
                {raisedAmount} for {sweep?.organisation?.name}
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
}

function getRaisedAmount(entryCount, sweep) {
  if (sweep.id === 'masters23') {
    return 5600.0;
  }
  const prize = getPrizeForKey(sweep?.prize_breakdown);

  const totalPrizeMoney = prize?.monetary
    ? prize?.prizes.reduce((partialSum, a) => partialSum + a, 0)
    : 0;

  const totalMoneyIn = entryCount * 8.6; // 10 euro - sweeps take

  return totalMoneyIn - totalPrizeMoney > 0
    ? totalMoneyIn - totalPrizeMoney
    : 0;
}

export default Supporting;
