import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import CartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ItemList from './ItemList';
import { CartContext } from '../../../context/cart';

function ShoppingCartIconContainer() {
  const [anchorCart, setAnchorCart] = useState(null);
  const { cartItems, totalPriceInCurrency } = useContext(CartContext);
  const navigate = useNavigate();

  const handleOpenCart = (event) => {
    setAnchorCart(event.currentTarget);
  };

  const handleCloseCart = () => {
    setAnchorCart(null);
  };

  const navigateToCheckout = () => {
    handleCloseCart();
    navigate('/checkout');
  };

  const navigateToSweeps = () => {
    handleCloseCart();
    navigate('/sweeps');
  };

  return (
    <>
      <Badge
        badgeContent={cartItems.length}
        color="primary"
        onClick={handleOpenCart}
      >
        <Avatar>
          <CartIcon alt="Cart" />
        </Avatar>
      </Badge>

      <Menu
        sx={{ mt: '45px', paddingBottom: '0px' }}
        id="cart-appbar"
        anchorEl={anchorCart}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorCart)}
        onClose={handleCloseCart}
      >
        <Card sx={{ minWidth: 350, paddingBottom: '0px' }}>
          <CardContent>
            <Typography
              sx={{
                fontFamily: 'hattonBold',
              }}
            >
              {cartItems.length === 0 ? 'Your cart is empty' : 'Your cart'}
            </Typography>
            <ItemList />
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontFamily: 'hattonBold',
                  }}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{totalPriceInCurrency()}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            {cartItems.length === 0 && (
              <Button
                size="small"
                variant="contained"
                className="hover-white"
                onClick={() => navigateToSweeps()}
                sx={{
                  width: '95%',
                  borderRadius: '10px',
                  backgroundColor: '#006747',
                }}
              >
                View Sweeps
              </Button>
            )}
            {cartItems.length > 0 && (
              <Button
                size="small"
                variant="contained"
                className="hover-white"
                disabled={cartItems.length === 0}
                onClick={() => navigateToCheckout()}
                sx={{
                  width: '95%',
                  borderRadius: '10px',
                  backgroundColor: '#006747',
                }}
              >
                Checkout
              </Button>
            )}
          </CardActions>
        </Card>
      </Menu>
    </>
  );
}

export default ShoppingCartIconContainer;
