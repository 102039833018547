/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Card from '../Layouts/Card';
import { getUrlAlias } from '../../Helpers/SweepsHelper';
import { getReferralLink } from '../../Helpers/ReferralsHelper';
import { CopyReferralCode } from '../Referrals/CopyReferralCode';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import EditIcon from '@mui/icons-material/Edit';
import LiveTvIcon from '@mui/icons-material/LiveTv';

function Success(props) {
  const { sweep, getTwitterShareText, myReferralCode } = props;
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
            <i className="fa fa-check w3-text-teal w3-center"></i>
            <span className="ml-2">
              Thanks for entering the {sweep.organisation.name} sweepstakes!
            </span>
          </h3>
        </Grid>

        {!sweep?.private_sweep && (
          <Grid item xs={12} sm={6}>
            <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
              Fancy a free entry?
            </h3>
            <p className="w3-center">
              Invite friends to get yourself a free entry! Share{' '}
              <a href={getReferralLink(sweep, myReferralCode)}>
                your referral link
              </a>{' '}
              with your friends.
            </p>
            <p className="w3-center">
              Every 5th team entered using your link will earn you a free entry
              to this sweep.
            </p>
            <CopyReferralCode sweep={sweep} referralCode={myReferralCode} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <ul style={{ listStyleType: 'none' }} className="ml-0 pl-2 pr-2">
            <li className="pb-3">
              <ScoreboardIcon className="w3-text-teal mr-2" /> The leaderboard
              will be available{' '}
              <Link to={'/' + getUrlAlias(sweep) + '/leaderboard'}>here</Link>
            </li>
            <li className="pb-3">
              <EditIcon className="w3-text-teal mr-2" /> You can edit your picks
              until the entries close on
              {' ' +
                new Date(sweep?.competition?.entry_close_date).toLocaleString()}
              , by logging in and selecting edit against your entry.
            </li>
            <li className="pb-3">
              <LiveTvIcon className="w3-text-teal mr-2" /> You can view your
              selections, and more importantly, how you're doing throughout the
              competition in real-time as it updates automatically.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <div className="w3-center pb-2">
            Share your entry with your friends!
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://twitter.com/intent/tweet?text=' +
                getTwitterShareText(sweep, myReferralCode)
              }
            >
              <i className="fa fa-twitter w3-text-teal w3-margin-left w3-large w3-center"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={
                'https://wa.me/?text=' +
                getTwitterShareText(sweep, myReferralCode)
              }
              data-action="share/whatsapp/share"
            >
              <i className="fa fa-whatsapp w3-margin-left w3-large w3-text-teal w3-center"></i>
            </a>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Success;
