import React, { useContext } from 'react';
import { CartContext } from '../../context/cart';

const SubmitNoPaymentTeam = (props) => {
  const { sweep, submitSelections, freeEntryCount } = props;
  const { cartItems } = useContext(CartContext);

  return (
    <div className="w3-padding">
      <h3 className="w3-text-teal sweeps-font-bold">Submit Team</h3>

      {!sweep?.private_sweep && (
        <p>
          You are claiming {cartItems.length} free entries based on the number
          of usages of your referral code.
        </p>
      )}

      {sweep?.private_sweep && (
        <p>
          By clicking below, you are submitting your entries. Entries can be
          edited until the competition closing date.
        </p>
      )}

      <button
        data-testid="submitButton"
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        onClick={() => submitSelections('', freeEntryCount)}
      >
        Submit Team
      </button>
    </div>
  );
};

export { SubmitNoPaymentTeam };
