/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Error from '../Error';

function CurrencyField(props) {
  const { errors, handleChange, value } = props;

  const currencies = [
    {
      key: 'eur',
      name: 'EURO',
    },
    {
      key: 'gbp',
      name: 'POUNDS',
    },
    {
      key: 'usd',
      name: 'DOLLARS',
    },
  ];

  const input = (
    <select
      defaultValue={'eur'}
      onChange={handleChange}
      className="w3-select"
      name="currency"
    >
      {value && value !== 'none' && (
        <option value={value} selected>
          SELECTED:{' '}
          {currencies.find((currency) => currency.key === value)?.name}
        </option>
      )}
      {currencies.map((currency) => {
        return (
          <option key={currency.key} value={currency.key}>
            {currency.name}
          </option>
        );
      })}
    </select>
  );

  return (
    <React.Fragment>
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Select Currency</b>
      </label>
      {errors && errors['currency'] && <Error error={errors['currency']} />}
      {input}
    </React.Fragment>
  );
}

export default CurrencyField;
