/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useContext } from 'react';
import {
  getMatchesDataForSweep,
  getNumberOfGroups,
} from '../../../Helpers/RugbyMatchesHelper';
import { CartContext } from '../../../context/cart';

export const useRugbyForm = ({ sweep, setNumberOfGroups }) => {
  const { addToCart } = useContext(CartContext);
  const [state, setState] = useState({
    picks: {},
    matches: [],
  });

  useEffect(() => {
    if (sweep && sweep.competition.sport === 'rugby') {
      getMatchesDataForSweep(sweep, updateMatches);
    }
  }, [sweep]);

  const updateMatches = (matchesData) => {
    setNumberOfGroups(getNumberOfGroups(matchesData));
    setState({
      ...state,
      matches: matchesData,
    });
  };

  function handleMatchPicked(matchId, lowerBound, upperBound, teamId, draw) {
    setState({
      ...state,
      picks: {
        ...state.picks,
        [matchId]: {
          matchId,
          teamId,
          lowerBound,
          upperBound,
          draw,
        },
      },
    });
  }

  function addEntryToCart(
    sweep,
    userId,
    email,
    nickName,
    referralCode,
    tieBreaker,
  ) {
    const cartItem = {
      id: sweep.id + '-' + userId + '-' + nickName + '-' + Date.now(),
      sweep,
      userId,
      email,
      nickName,
      tieBreaker,
      picks: state.picks,
    };
    addToCart(cartItem, referralCode);
    resetPicks();
  }

  function resetPicks() {
    setState({
      ...state,
      picks: {},
    });
  }

  return {
    handlers: {
      handleMatchPicked,
      addEntryToCart,
    },
    context: {
      matches: state.matches,
      picks: state.picks,
    },
  };
};
