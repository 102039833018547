import React from 'react';

function Errors(props) {
  const { errors } = props;

  return (
    <>
      {errors !== '' && errors !== null && errors && (
        <>
          <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
            <p className="w3-margin-top">
              <i className={`fa fa-warning fa-fw w3-margin-right`} />
              {errors}
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default Errors;
