/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from 'react';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { useRugbyCheckout } from './useRugbyCheckout';
import { useGolfCheckout } from './useGolfCheckout';
import { useHorseAuctionCheckout } from './useHorseAuctionCheckout';
import { validateReferral } from '../../../Helpers/ReferralsHelper';

export const useCheckout = ({ sweep, user, referralCode }) => {
  const [state, setState] = useState({
    errors: {},
    referralCode,
    myReferralCode: '',
    successfulSignup: false,
    freeEntryValid: false,
    freeEntryCount: 0,
  });

  useEffect(() => {
    if (user) {
      validateReferral(sweep, user, setMyFreeEntryInformation);
    }
  }, [user]);

  const { handlers: rugbyHandlers } = useRugbyCheckout();
  const { handlers: golfHandlers } = useGolfCheckout();
  const { handlers: horseAuctionHandlers } = useHorseAuctionCheckout();

  const competitionHandlersMap = {
    rugby: rugbyHandlers,
    golf: golfHandlers,
    horseAuction: horseAuctionHandlers,
  };

  const competitionCheckoutHandlers =
    competitionHandlersMap[sweep?.competition?.sport];

  const handleSuccessfulSubmission = (referralCode) => {
    setState({
      ...state,
      successfulSignup: true,
      myReferralCode: referralCode,
    });
  };

  const setMyFreeEntryInformation = (valid, freeEntryCount) => {
    setState({
      ...state,
      freeEntryValid: valid,
      freeEntryCount: freeEntryCount,
    });
  };

  const handlFailedSubmission = (error) => {
    setState({
      ...state,
      errors: {
        ...state.errors,
        submitSelections: {
          messages: [error.response.data.message],
        },
      },
    });
  };

  function submitSelections(paymentId, freeEntryCount) {
    if (freeEntryCount > 0) {
      logMetric('referral', 'signupWithFreeEntry');
    }
    competitionCheckoutHandlers.onSubmit(
      paymentId,
      freeEntryCount,
      handleSuccessfulSubmission,
      handlFailedSubmission,
    );
  }

  const getObjectToStoreInStripe = () => {
    return competitionCheckoutHandlers.getObjectShape();
  };

  return {
    state,
    handlers: {
      ...competitionCheckoutHandlers,
      getObjectToStoreInStripe,
      submitSelections,
    },
  };
};
