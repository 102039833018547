import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Card from '../Layouts/Card';
import Loader from '../Layouts/Loader';
import SignupWrapper from './SignupWrapper';
import { isEntriesFinished, isEntriesStarted } from '../../Helpers/MiscHelpers';
import Details from '../Sweeps/Details';
import axios from 'axios';

const SweepsSignup = (props) => {
  const { fetchUserDetails, user } = props;
  const { sweep_id } = useParams();

  const location = useLocation();
  const scrollToReference = useRef(null);

  const [sweep, setSweep] = useState(null);

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    fetchSweep(sweep_id);
  }, []);

  const fetchSweep = async (sweep_id) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/sweeps?sweep_id=' + sweep_id,
    );
    setSweep(response.data.sweeps[0]);
  };

  const getReferralCode = () => {
    const queryParameter = new URLSearchParams(location.search);
    return queryParameter.get('referral') || '';
  };

  const getType = () => {
    const queryParameter = new URLSearchParams(location.search);
    return queryParameter.get('type') || '';
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
  }, []);

  return (
    <>
      <div
        style={{ top: '0', position: 'absolute', width: '100%' }}
        ref={scrollToReference}
      ></div>
      <div className="w3-row-padding w3-margin-bottom">
        <div className="w3-margin-top">
          <div className="w3-margin-top">
            <div className="w3-row">
              {sweep &&
                (!isEntriesStarted(sweep.competition) ||
                  isEntriesFinished(sweep.competition)) && (
                  <Details sweep={sweep} />
                )}
              {sweep &&
                isEntriesStarted(sweep.competition) &&
                !isEntriesFinished(sweep.competition) && (
                  <SignupWrapper
                    fetchUserDetails={fetchUserDetails}
                    user={user}
                    sweepId={sweep_id}
                    sweep={sweep}
                    referralCode={getReferralCode()}
                    scrollToReference={scrollToReference}
                    entryType={getType()}
                  />
                )}
            </div>
          </div>

          {!sweep && <Loader />}

          <div className="w3-container">
            <div className="w3-half">
              <Card>
                <img
                  src={sweep?.competition?.image_url}
                  alt={sweep?.competition?.name}
                  style={{ width: '100%' }}
                />
              </Card>
            </div>
            {sweep?.competition?.sport === 'golf' && (
              <div className="w3-container h-100 w3-card w3-margin-left w3-margin-top w3-white">
                <iframe
                  src="https://open.spotify.com/embed/show/5OsOHwevnI6UhwTgxeYDTQ"
                  title="Unplayable Podcast"
                  className="w3-margin-top"
                  width="100%"
                  height="232"
                  frameBorder="0"
                  allow="encrypted-media"
                ></iframe>
              </div>
            )}
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default SweepsSignup;
