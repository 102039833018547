/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { isCompetitionEndDateInFuture } from '../../Helpers/CompetitionHelper';
import SweepCard from './Card';
import { useLoadSweeps } from '../../features/useLoadSweeps';
import { Link } from 'react-router-dom';
import Loader from '../Layouts/Loader';

function SweepsGallery(props) {
  const { future, results } = props;

  const { getSweepsArray, sweepsStatus } = useLoadSweeps();

  const sweeps = getSweepsArray();

  const getSweepsForView = (allSweeps, results, future) => {
    if (results) {
      return allSweeps.filter(
        (sweep) =>
          !isCompetitionEndDateInFuture(sweep?.competition) &&
          !sweep.private_sweep,
      );
    }

    const sweepsForView = allSweeps.filter(
      (sweep) =>
        ((future && isCompetitionEndDateInFuture(sweep?.competition)) ||
          !future) &&
        !sweep.private_sweep,
    );

    return sweepsForView.length === 0 && future
      ? sweepsForView
          .filter((sweep) => !isCompetitionEndDateInFuture(sweep?.competition))
          .slice(0, 3)
      : sweepsForView;
  };

  const sweepsForView = getSweepsForView(sweeps, results, future).sort(
    function (a, b) {
      return (
        new Date(b.competition?.start_date) -
        new Date(a.competition?.start_date)
      );
    },
  );

  return (
    <>
      <div className="w3-row">
        {sweepsStatus === 'loading' && sweeps.length == 0 && <Loader />}
        {sweepsForView.length === 0 && future && (
          <div className="w3-center">
            <p className="w3-center">
              No open sweeps at this time. View{' '}
              <Link to="/results">past results</Link>
            </p>
          </div>
        )}
      </div>
      <div className="w3-row-padding w3-row">
        {sweepsForView.length > 0 &&
          sweepsForView.map((sweep) => (
            <SweepCard key={sweep.id} sweep={sweep} />
          ))}
      </div>
    </>
  );
}

export default SweepsGallery;
