import React, { useContext, useEffect, useState } from 'react';

import { CartContext } from '../../context/cart';
import InitPayment from '../Payment/InitPayment';
import { SubmitNoPaymentTeam } from '../Payment/SubmitNoPaymentTeam';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import Errors from '../Payment/Errors';

function PaymentWrapper(props) {
  const { freeEntryCount, submitSelections, getObjectToStoreInStripe } = props;
  const { cartItems, getSweep, getEmail } = useContext(CartContext);

  const apiUrl = process.env.REACT_APP_API_URL;
  const [freeEntry, setFreeEntry] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [generatedClientSecret, setGeneratedClientSecret] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(true);
  const sweep = getSweep();

  useEffect(() => {
    axios
      .post(apiUrl + '/entries/createPayment', {
        data: {
          receipt_email: getEmail(),
          object_to_store: getObjectToStoreInStripe(),
          sweep_id: sweep.id,
        },
      })
      .then(function (response) {
        if (response.data.paymentIntent) {
          setGeneratedClientSecret(response.data.paymentIntent.client_secret);
          setPaymentIntentId(response.data.paymentIntent.id);
          setPaymentIntent(response.data.paymentIntent);
        } else {
          setFreeEntry(true);
        }
        setDiscount(response.data.discountAmount);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setErrors(error?.response?.message);
        setLoading(false);
      });
  }, [cartItems]);

  if (loading) {
    return (
      <Oval
        wrapperStyle={{ margin: 'auto' }}
        ariaLabel="loading-indicator"
        height={50}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={1}
        color="teal"
        secondaryColor="white"
      />
    );
  }

  if (errors !== '' && errors !== null && errors) {
    return <Errors errors={errors} />;
  }

  return cartItems.length > freeEntryCount && !freeEntry ? (
    <InitPayment
      submitSelections={submitSelections}
      sweep_id={sweep.id}
      sweep={sweep}
      cartItems={cartItems}
      connected_account_id={sweep.connected_account_id}
      paymentIntent={paymentIntent}
      paymentIntentId={paymentIntentId}
      generatedClientSecret={generatedClientSecret}
      discount={discount}
      freeEntryCount={freeEntryCount}
    />
  ) : (
    <SubmitNoPaymentTeam
      sweep={sweep}
      submitSelections={submitSelections}
      freeEntryCount={freeEntryCount}
    />
  );
}

export default PaymentWrapper;
