import React from 'react';
import Field from '../Form/Fields/Field';

import CompetitionsField from '../Form/Fields/CompetitionsField';
import PrizeField from '../Form/Fields/PrizeField';
import CheckboxField from '../Form/Fields/CheckboxField';
import SelectOrganisation from '../Form/Fields/OrganisationsField';
import CurrencyField from '../Form/Fields/CurrencyField';

function Form(props) {
  const {
    errors,
    onBlur,
    handleChange,
    organisation_id,
    prize_breakdown,
    competition_id,
    alias,
    the_sweep,
    private_sweep,
    entry_fee,
    platform_fee,
    anyscor,
    currency,
  } = props;

  return (
    <>
      <CheckboxField
        label="The Sweep"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="the_sweep"
        value={the_sweep}
        type="checkbox"
      />
      <CompetitionsField
        value={competition_id}
        errors={errors}
        handleChange={handleChange}
      />
      <Field
        label="URL Alias - Should not contain spaces, must be unique, not editable"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="alias"
        placeholder="masters_23_louth_lfga"
        value={alias}
      />

      <CheckboxField
        label="Anyscor Sweep?"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="anyscor"
        value={anyscor}
        type="checkbox"
      />

      <Field
        label="Entry Fee (in cents)"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        placeholder="1000"
        name="entry_fee"
        value={entry_fee}
      />

      <CurrencyField
        errors={errors}
        handleChange={handleChange}
        value={currency}
      />

      <label className={`w3-text-teal w3-margin-top`}>
        <b>Image:</b>
      </label>
      <input
        label="Upload files"
        type="file"
        onChange={handleChange}
        name="file"
      />
      <br />

      {!the_sweep && (
        <>
          <PrizeField
            value={prize_breakdown}
            errors={errors}
            handleChange={handleChange}
          />
          <CheckboxField
            label="Private Sweep"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="private_sweep"
            value={private_sweep}
            type="checkbox"
          />
          <hr />
          <h5 className="sweeps-font-bold w3-text-teal">Charity Fields</h5>
          <SelectOrganisation
            id={organisation_id}
            handleChange={handleChange}
          />
          <Field
            label="Platform Fee (Sweeps fee in cents)"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            placeholder="100"
            name="platform_fee"
            value={platform_fee}
          />
          <hr />
        </>
      )}
    </>
  );
}

export default Form;
