/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useContext } from 'react';
import { getPlayersDataForSweep } from '../../../Helpers/PlayerHelper';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { CartContext } from '../../../context/cart';

const TIEBREAK_SLIDE = 50;

export const useGolfForm = ({
  sweep,
  setNumberOfGroups,
  setActiveSlide,
  executeScroll,
}) => {
  const { addToCart } = useContext(CartContext);
  const [state, setState] = useState({
    group_1: '',
    group_2: '',
    group_3: '',
    group_4: '',
    group_5: '',
    players: [],
    groupOnePlayers: [],
    groupTwoPlayers: [],
    groupThreePlayers: [],
    groupFourPlayers: [],
    groupFivePlayers: [],
    randomPick: false,
  });

  useEffect(() => {
    if (sweep && sweep.competition.sport === 'golf') {
      getPlayersDataForSweep(sweep, updateGroupsState);
    }
  }, [sweep]);

  const updateGroupsState = (playersData) => {
    setNumberOfGroups(5);
    setState({
      ...state,
      players: playersData,
      groupOnePlayers: playersData.filter((player) => player.group === 1),
      groupTwoPlayers: playersData.filter((player) => player.group === 2),
      groupThreePlayers: playersData.filter((player) => player.group === 3),
      groupFourPlayers: playersData.filter((player) => player.group === 4),
      groupFivePlayers: playersData.filter((player) => player.group === 5),
    });
  };

  const pickTeam = () => {
    executeScroll();
    logMetric('signup', 'randomPick');
    let randomPicks = [
      state.groupOnePlayers[
        Math.floor(Math.random() * state.groupOnePlayers.length)
      ],
      state.groupTwoPlayers[
        Math.floor(Math.random() * state.groupTwoPlayers.length)
      ],
      state.groupThreePlayers[
        Math.floor(Math.random() * state.groupThreePlayers.length)
      ],
      state.groupFourPlayers[
        Math.floor(Math.random() * state.groupFourPlayers.length)
      ],
      state.groupFivePlayers[
        Math.floor(Math.random() * state.groupFivePlayers.length)
      ],
    ];

    handleAllPicked(randomPicks);
  };

  const addEntryToCart = (
    sweep,
    userId,
    email,
    nickName,
    referralCode,
    tieBreaker,
  ) => {
    const cartItem = {
      id: sweep.id + '-' + userId + '-' + nickName + '-' + Date.now(),
      sweep,
      userId,
      email,
      nickName,
      tieBreaker,
      group_1: state.group_1,
      group_2: state.group_2,
      group_3: state.group_3,
      group_4: state.group_4,
      group_5: state.group_5,
    };
    addToCart(cartItem, referralCode);
    resetPicks();
  };

  function resetPicks() {
    setState({
      ...state,
      group_1: '',
      group_2: '',
      group_3: '',
      group_4: '',
      group_5: '',
      randomPick: false,
    });
  }

  const handleAllPicked = (playersArray) => {
    setState({
      ...state,
      group_1: playersArray[0].id,
      group_2: playersArray[1].id,
      group_3: playersArray[2].id,
      group_4: playersArray[3].id,
      group_5: playersArray[4].id,
      randomPick: true,
    });
    setActiveSlide(TIEBREAK_SLIDE);
  };

  function handlePlayerPicked(id, group) {
    setState({
      ...state,
      [group]: id,
    });
  }

  return {
    handlers: {
      addEntryToCart,
      pickTeam,
      handlePlayerPicked,
    },
    context: {
      sweep,
      players: state.players,
      groupOnePlayers: state.groupOnePlayers,
      groupTwoPlayers: state.groupTwoPlayers,
      groupThreePlayers: state.groupThreePlayers,
      groupFourPlayers: state.groupFourPlayers,
      groupFivePlayers: state.groupFivePlayers,
      group_1: state.group_1,
      group_2: state.group_2,
      group_3: state.group_3,
      group_4: state.group_4,
      group_5: state.group_5,
    },
  };
};
