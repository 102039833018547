/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PasswordValidator from 'password-validator';
import SuccessPanel from '../../Form/SuccessPanel';
import ResetPassword from './ResetPassword';
import CreatePassword from './CreatePassword';
import Login from './Login';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { toast } from 'react-toastify';
import { CartContext } from '../../../context/cart';

const apiUrl = process.env.REACT_APP_API_URL;

function AuthWrapper(props) {
  const {
    email,
    fetchUserDetails,
    successMessage,
    setRegisterState,
    onSuccess,
  } = props;
  const navigate = useNavigate();
  const { getEmail, hasCartItems, clearCart } = useContext(CartContext);
  const [state, setState] = React.useState({
    email: email,
    password: '',
    newPasswordRequired: false,
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: '',
    loggedIn: false,
    loginFailed: false,
    forgotPasswordFailed: false,
    forgotPasswordCodeSent: false,
    updatedPassword: false,
    session: '',
    errors: {},
    isResettingPassword: false,
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function updatePassword() {
    callLoginApi({
      username: state.email.trim(),
      password: state.newPassword,
      challengeName: 'NEW_PASSWORD_REQUIRED',
      session: state.session,
    });
  }

  function handleSubmit() {
    if (state.email !== '' && state.password !== '') {
      callLoginApi({
        username: state.email.trim(),
        password: state.password,
      });
    } else {
      addErrors('Incorrect email or password');
    }
  }

  function verifyForgottenPasswordCode() {
    if (
      state.email !== '' &&
      state.newPassword !== '' &&
      state.verificationCode !== '' &&
      state.confirmNewPassword !== '' &&
      state.newPassword === state.confirmNewPassword
    ) {
      callVerifyForgottenPasswordApi({
        username: state.email.trim(),
        password: state.newPassword,
        code: state.verificationCode.trim(),
      });
    } else {
      addErrors('Missing required values');
    }
  }

  function requestForgottenPasswordCode() {
    logMetric('auth', 'requestForgottonPassword');
    if (state.email !== '') {
      callRequestForgottenPasswordApi({
        username: state.email.trim(),
      });
    } else {
      addErrors('Missing email is required');
    }
  }

  function callVerifyForgottenPasswordApi(data) {
    axios
      .post(apiUrl + '/account/verifyPasswordReset', data)
      .then(function (response) {
        if (response.data.success) {
          setState({
            ...state,
            forgotPasswordCodeSent: false,
            isResettingPassword: false,
            updatedPassword: true,
            errors: {},
          });
        }
      })
      .catch(function (error) {
        addErrors(error.response.data.message);
      });
  }

  function callRequestForgottenPasswordApi(data) {
    axios
      .post(apiUrl + '/account/requestPassword', data)
      .then(function (response) {
        if (response.data.success) {
          setState({
            ...state,
            forgotPasswordCodeSent: true,
            errors: {},
          });
        }
      })
      .catch(function (error) {
        addErrors(error.response.data.message);
      });
  }

  function addErrors(message) {
    setState({
      ...state,
      loginFailed: true,
      errors: {
        ...state.errors,
        form: {
          messages: [message],
        },
      },
    });
  }

  function callLoginApi(data) {
    axios
      .post(apiUrl + '/login', data)
      .then(function (response) {
        if (isValidLogin(response)) {
          logMetric('auth', 'successfulLogin');
          localStorage.setItem('user', JSON.stringify(response.data));
          setState({
            ...state,
            loggedIn: true,
            loginFailed: false,
          });
          fetchUserDetails();
          // if there is cart items with a different email than the user, we need to delete them
          if (hasCartItems() && getEmail() !== response.data.email) {
            toast.error(
              'Cart items have a different email than the logged in user. Clearing cart...',
            );
            clearCart();
          }
          if (onSuccess) {
            onSuccess('', '', '');
          }
          document.getElementById(`loginForm`).style.display = 'none'; // Close modal
        }
        if (isNewPasswordRequired(response)) {
          setState({
            ...state,
            newPasswordRequired: true,
            session: response.data.session,
            loginFailed: false,
          });
        }
      })
      .catch(function (error) {
        addErrors(error?.response?.data?.msg);
      });
  }

  function navigateToRegister() {
    document.getElementById('loginForm').style.display = 'none';
    if (setRegisterState) {
      setRegisterState();
    }
    navigate('/register');
  }

  function isValidLogin(response) {
    return response.data.refresh_token && response.data.id_token;
  }

  function isNewPasswordRequired(response) {
    return response.data.challengeName === 'NEW_PASSWORD_REQUIRED';
  }

  function setResetPassword(value) {
    setState({
      ...state,
      isResettingPassword: value,
      errors: {},
    });
  }

  // TODO: Improve this to be like the Register onBlur
  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;
    let schema = new PasswordValidator();
    schema
      .is()
      .min(8)
      .is()
      .max(100)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      toast.error('Mandatory field, please enter a value', {
        toastId: 'mandatory_field',
      });
      messages.push('Mandatory field, please enter a value');
    }
    if (evt.target.name === 'newPassword' && !schema.validate(value)) {
      addErrors = true;
      const failedRules = schema.validate(value, { details: true });
      failedRules.forEach((failedRule) =>
        toast.error(failedRule.message, {
          toastId: failedRule.message,
        }),
      );
    }
    if (
      evt.target.name === 'confirmNewPassword' &&
      value !== state.newPassword
    ) {
      addErrors = true;
      toast.error('Passwords must match!', {
        toastId: 'passwords_match',
      });
      messages.push('Passwords must match!');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
        },
      });
    }
  }

  return (
    <React.Fragment>
      <div className="w3-container" style={{ color: 'black' }}>
        {successMessage && (
          <>
            <SuccessPanel message={successMessage} />
          </>
        )}
        {state.updatedPassword && (
          <>
            <SuccessPanel message="Successfully updated password" />
          </>
        )}
        {state.loggedIn && (
          <>
            <SuccessPanel message="Successfully Signed In" />
          </>
        )}

        {state.isResettingPassword && !state.loggedIn && (
          <ResetPassword
            email={state.email}
            newPassword={state.newPassword}
            confirmNewPassword={state.confirmNewPassword}
            verificationCode={state.verificationCode}
            errors={state.errors}
            forgotPasswordCodeSent={state.forgotPasswordCodeSent}
            handleChange={handleChange}
            onBlur={onBlur}
            setResetPassword={setResetPassword}
            requestForgottenPasswordCode={requestForgottenPasswordCode}
            verifyForgottenPasswordCode={verifyForgottenPasswordCode}
          />
        )}
        {!state.isResettingPassword &&
          !state.newPasswordRequired &&
          !state.loggedIn && (
            <Login
              email={state.email}
              password={state.password}
              errors={state.errors}
              loginFailed={state.loginFailed}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              navigateToRegister={navigateToRegister}
              setResetPassword={setResetPassword}
            />
          )}
        {!state.isResettingPassword &&
          state.newPasswordRequired &&
          !state.loggedIn && (
            <CreatePassword
              newPassword={state.newPassword}
              confirmNewPassword={state.confirmNewPassword}
              errors={state.errors}
              loginFailed={state.loginFailed}
              handleChange={handleChange}
              onBlur={onBlur}
              updatePassword={updatePassword}
            />
          )}
      </div>
    </React.Fragment>
  );
}

export default AuthWrapper;
