import React, { useContext, useEffect } from 'react';

import ItemList from '../SweepsSignup/Cart/ItemList';
import { CartContext } from '../../context/cart';
import { Grid } from '@mui/material';
import { useCheckout } from './hooks/useCheckout';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import SweepsGallery from '../Sweeps/Gallery';
import SweepCard from '../Sweeps/Card';
import ListItem from '@mui/material/ListItem';
import PaymentWrapper from './PaymentWrapper';
import InfoIcon from '@mui/icons-material/Info';
import DiscountIcon from '@mui/icons-material/Discount';
import { isEntriesOpen } from '../../Helpers/MiscHelpers';
import { toast } from 'react-toastify';

function CheckoutWrapper(props) {
  const { user } = props;
  const { cartItems, clearCart, getSweep, getReferralCode } =
    useContext(CartContext);

  const sweep = getSweep();

  const { state, handlers } = useCheckout({ sweep, user });

  if (sweep && !isEntriesOpen(sweep?.competition)) {
    clearCart();
    toast.error('Entries are not open for this sweep');
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (state.successfulSignup) {
      clearCart();
      navigate(
        `/${sweep.alias}?myReferralCode=${state.myReferralCode}&successfulSignup=true`,
      );
    }
  }, [state.successfulSignup, state.myReferralCode, navigate, clearCart]);

  if (state.successfulSignup) {
    clearCart();
    navigate(
      `/${sweep.alias}?myReferralCode=${state.myReferralCode}&successfulSignup=true`,
    );
  }

  return cartItems.length > 0 ? (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8} className="pt-4 pl-4 pr-4">
        <Card>
          <PaymentWrapper
            submitSelections={handlers.submitSelections}
            getObjectToStoreInStripe={handlers.getObjectToStoreInStripe}
            freeEntryCount={state.freeEntryCount}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '20px' }}>
        {getReferralCode() || state.freeEntryValid ? (
          <Card className="mb-2">
            {getReferralCode() ? (
              <ListItem>
                <span>
                  <InfoIcon className="w3-text-teal mr-2" />
                  You've been referred to this sweep.
                </span>
              </ListItem>
            ) : null}
            {state.freeEntryValid ? (
              <ListItem>
                <span>
                  <DiscountIcon className="w3-text-teal mr-2" />
                  You have {state.freeEntryCount} free{' '}
                  {state.freeEntryCount > 1 ? 'entries' : 'entry'}. The discount
                  has been applied.
                </span>
              </ListItem>
            ) : null}
          </Card>
        ) : null}

        <Card>
          <ItemList />
        </Card>
        <SweepCard fullWidth={true} key={sweep.id} sweep={sweep} />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1}>
      <Grid xs={12} style={{ padding: '20px' }}>
        <Card>
          <div className="w3-center p-4">
            <ProductionQuantityLimitsIcon
              style={{ fontSize: '48px' }}
              className="w3-text-teal w3-center"
            />
            <h2 className="sweeps-font-bold w3-text-teal w3-animate-top w3-center">
              No items in cart
            </h2>
          </div>
        </Card>
      </Grid>
      <Grid xs={12} style={{ padding: '20px' }}>
        <SweepsGallery future={true} />
      </Grid>
    </Grid>
  );
}

export default CheckoutWrapper;
