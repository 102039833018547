import React from 'react';
import Header from '../Layouts/Header';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../Layouts/Footer';
import { ToastContainer } from 'react-toastify';

function FullPageWrapper(props) {
  const { fetchUserDetails, handleLogout, loading, user, child } = props;

  return (
    <>
      <div className="fullPageWrapper">
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          closeOnClick
          pauseOnHover
          theme="light"
        />
        {loading && (
          <>
            <CircularProgress />
          </>
        )}
        {!loading && (
          <>
            <Header
              fetchUserDetails={fetchUserDetails}
              handleLogout={handleLogout}
              loggedInUser={user}
            />
            {child}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default FullPageWrapper;
