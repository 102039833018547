import { useContext } from 'react';
import axios from 'axios';
import { CartContext } from '../../../context/cart';

// eslint-disable-next-line no-undef
const apiUrl = process.env.REACT_APP_API_URL;

export const useRugbyCheckout = () => {
  const { cartItems, getReferralCode } = useContext(CartContext);

  const onSubmit = (
    paymentId,
    freeEntryCount,
    callbackSuccess,
    callbackError,
  ) => {
    let allFreeEntries = freeEntryCount > cartItems.length ? true : false;
    axios
      .post(apiUrl + '/entries/create', {
        payment_intent_id: paymentId,
        all_free_entries: allFreeEntries,
        referral_code: getReferralCode(),
        entries: cartItems.map((item, index) => ({
          free_entry: allFreeEntries || index < freeEntryCount,
          user_id: item.userId,
          email: item.email,
          nickName: item.nickName,
          picks: item.picks,
          sweep_id: item.sweep.id,
          tie_breaker: item.tieBreaker,
        })),
      })
      .then(function (response) {
        console.log('response');
        console.log(response);
        if (response.data.message.includes('Successfully created entries')) {
          console.log('success');
          callbackSuccess(response.data.referral_code);
        }
      })
      .catch(function (error) {
        callbackError(error);
      });
  };

  const getObjectShape = () => {
    return cartItems.map((item) => ({
      picks: item.picks,
      tie_breaker: item.tieBreaker,
      nickName: item.nickName,
      sweep_id: item.sweep.id,
    }));
  };

  return {
    handlers: {
      onSubmit,
      getObjectShape,
    },
  };
};
