/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import currencyToSymbolMap from 'currency-symbol-map/map';

function InitPayment(props) {
  const {
    connected_account_id,
    generatedClientSecret,
    paymentIntentId,
    paymentIntent,
    submitSelections,
    sweep,
    cartItems,
    discount,
    freeEntryCount,
  } = props;

  let stripePromise;
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
      stripeAccount: connected_account_id[0]['account_id'],
    });
  }

  const options = {
    clientSecret: generatedClientSecret,
  };

  return (
    <div className="w3-padding">
      <h3 className="w3-text-teal" style={{ fontFamily: 'hattonBold' }}>
        Payment Method
      </h3>
      {generatedClientSecret && stripePromise && (
        <>
          {(!discount || discount <= 0) && (
            <p style={{ fontWeight: 'bold' }}>
              A charge of {currencyToSymbolMap[sweep?.currency?.toUpperCase()]}
              {paymentIntent?.amount / 100} will be made to your card to{' '}
              {sweep.organisation.name}
            </p>
          )}
          {discount > 0 && (
            <ul style={{ listStyleType: 'none' }} className="pl-0">
              <li>
                {currencyToSymbolMap[sweep?.currency?.toUpperCase()]}
                {cartItems.length * (sweep.entry_fee / 100)} total
              </li>
              <li>
                - {currencyToSymbolMap[sweep?.currency?.toUpperCase()]}
                {discount / 100} discount from free entries
              </li>
              <hr />
              <li style={{ fontWeight: 'bold' }}>
                A charge of{' '}
                {currencyToSymbolMap[sweep?.currency?.toUpperCase()]}
                {paymentIntent?.amount / 100} will be made to your card to card
                to {sweep.organisation.name}
              </li>
            </ul>
          )}
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm
              freeEntryCount={freeEntryCount}
              submitSelections={submitSelections}
              paymentIntentId={paymentIntentId}
            />
          </Elements>
        </>
      )}
    </div>
  );
}

export default InitPayment;
