/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
  return (
    <div className="w3-center w3-container" style={{ height: '200px' }}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
